import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import BaseSkeleton from '@/components/base/baseSkeleton';
import BaseTopNav from '@/components/base/baseTopNav';
import useStateHooks from '@/pages/invite/index/useStateHooks';
import { useModel } from '@umijs/max';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { currentInvitationCodeData, currentInvitationCodeLoading ,invitationCodeQR} =
    useStateHooks();
  const { appInfo } = useModel('appInfo');
  const { isPc } = useModel('system');
  const { t } = useTranslation();
  return (
    <div className="w-full  ">
      <BaseTopNav title={t('邀请好友')} />
      <div
        className="pt-4 bg-[#010101] h-[90vh] bg-bottom"
        style={{
          backgroundImage: `url(${
            isPc ? require('@/assets/img/invite/Invite_friends.png') : ''
          })`,
          backgroundRepeat: 'no-repeat',
          // backgroundClip: 'content-box',
          backgroundSize: '100%',
        }}
      >
        <div className="w-full text-start flex items-center px-4">
          {appInfo?.APP_BASE_LOGO ? (
            <img
              src={appInfo?.APP_BASE_LOGO}
              className="w-[28px] cursor-pointer  box-content "
              alt="logo"
            />
          ) : (
            <BasilUserSolid className="ml-3 mr-3 text-2xl text-titleColor" />
          )}
          <span className="text-[16px] leading-[17px] font-bold text-[#fff] ml-[6px]">
            {appInfo?.APP_BASE_NAME}
          </span>
        </div>
        {/*  */}
        <div className="w-full mt-[32px] flex flex-wrap">
          <div className="w-full flex flex-wrap justify-center text-[#fff] text-[32px] leading-[40px] font-semibold ">
            <div className="w-full flex justify-center ">
              {t('享最高')}
              {currentInvitationCodeLoading ? (
              <BaseSkeleton isLoaded={currentInvitationCodeLoading}>
                <div className=" flex justify-between bg-black/40 w-[62px] rounded-md h-[40px]">
                  <span className="text-xs font-bold text-auxiliaryTextColor">
                    &nbsp;
                  </span>
                </div>
              </BaseSkeleton>
            )  : (
                <span className="text-primary">
                  {currentInvitationCodeData?.maxRebatePercentage}%
                </span>
              )}

              {t('的')}
            </div>
            <div className="w-full text-center">{t('现货邀请返佣')}</div>
          </div>
          <div className="w-full flex justify-center text-[#fff] text-[16px] leading-[22px]">
            {t('买好币，上{{data}}', { data: 'LIBERTYCOIN' })}
          </div>
        </div>
      </div>
      {/* 图片 */}
      {!isPc && (
        <div
          className={`w-full h-[53vh] bg-cover bg-center bg-no-repeat bg-bottom fixed bottom-[105px]`}
          style={{
            backgroundImage: `url(${require('@/assets/img/invite/Invite_friends.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundClip: 'content-box',
            backgroundSize: '100% 100%',
          }}
        />
      )}
      <div
        className={`bg-[#fff] w-full flex justify-between items-center py-[5px] text-[#fff] px-4 ${
          !isPc ? 'fixed bottom-[0px]' : ''
        }`}
      >
        <div className="w-full h-fit flex flex-wrap pr-2">
          <span className="w-full flex text-[18px] leading-[24px] text-[#000]">
            {t('邀请码')}：
            {currentInvitationCodeLoading ? (
              <BaseSkeleton isLoaded={currentInvitationCodeLoading}>
                <div className=" flex justify-between bg-backgroundAuxiliaryColor w-[100px] rounded-md h-[24px]">
                  <span className="text-xs font-bold text-auxiliaryTextColor">
                    &nbsp;
                  </span>
                </div>
              </BaseSkeleton>
            ) : (
              currentInvitationCodeData?.popularizeCode ?? '--'
            )}
          </span>
          <span className="w-full text-[14px] leading-[20px] mt-[8px] text-[#868D9A]">
            {t('一站式数字资产综合服务平台')}
          </span>
        </div>
        <div className="p-[6.5px] border-[1px] border-solid border-[#ebebeb]">
          <QRCode
            value={invitationCodeQR??currentInvitationCodeData?.popularizeUrl}
            bgColor="#fff"
            size={80} // 二维码的大小
          />
        </div>
      </div>
    </div>
  );
};
