import { GetTextEnum } from '@/enums/businessEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import {
  createInvitationCodeApi,
  getInvitationCodeListApi,
  getMemberInvitationInformationApi,
  getPrivacyAgreementApi,
  getRebateTotalApi,
  getTheCurrentDefaultInvitationCodeApi,
  getInviteStatisticsApi,
  modifyInvitationCodeApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';

export default () => {
  const { t } = useTranslation();
  //获取返佣规则
  const { data: rebateRules, run: getRebateRulesReq } = useReq(
    getPrivacyAgreementApi,
    {
      manual: true,
    },
  );
  //获取当前会员返佣比例
  const {
    runAsync: getMemberInvitationInformationReq,
    data: memberInvitationInformationData,
  } = useReq(getMemberInvitationInformationApi, {
    manual: true,
  });
  //获取总奖励
  const { run: getRebateTotalReq, data: rebateTotalData } = useReq(
    getRebateTotalApi,
    {
      manual: true,
    },
  );
  // 缓存邀请二维码地址 【二维码固定】
  const [invitationCodeQR, setInvitationCodeQR] = useLocalStorage({
    key: CacheEnum.APP_INVITATION_CODE,
    defaultValue: {},
  });
  //获取当前默认邀请码信息
  // const {
  //   run: getTheCurrentDefaultInvitationCodeReq,
  //   data: currentInvitationCodeData,
  // } = useReq(getTheCurrentDefaultInvitationCodeApi, {
  //   manual: true,
  // }) as any;
  const {
    run: getTheCurrentDefaultInvitationCodeReq,
    data: currentInvitationCodeData,
    loading: currentInvitationCodeLoading,
  } = useReq(getInviteStatisticsApi, {
    manual: true,
    onSuccess(data: any){
      // 缓存邀请二维码地址 【二维码固定】
      setInvitationCodeQR(data?.popularizeUrl)
    }
  }) as any;
  //获取列表
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
  });
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: getInvitationCodeListApi,
      params,
      setParams,
      manual:true
    });
  useMount(() => {
    //获取返佣规则
    getRebateRulesReq({ type: GetTextEnum.SYSTEM_REBATE_RULES });
    //获取总奖励
    // getRebateTotalReq();
    // getMemberInvitationInformationReq();
    getTheCurrentDefaultInvitationCodeReq();
  });

  //新增表单
  const [recommendForm, setRecommendForm] = useState({
    remark: '',
    rebateRatio: 1,
    defaultCommend: false,
  });
  const reload = () => {
    getRebateTotalReq();
    setParams({
      pageNo: 1,
      pageSize: 10,
    });
    getTheCurrentDefaultInvitationCodeReq();
  };
  const { runAsync: createInvitationCodeReq } = useReq(
    createInvitationCodeApi,
    {
      manual: true,
      onSuccess() {
        toast.success(t('添加成功'));
        reload();
        setRecommendForm({
          remark: '',
          rebateRatio: 20,
          defaultCommend: false,
        });
      },
    },
  );
  //修改备注或者默认
  const { runAsync: modifyInvitationCodeReq } = useReq(
    modifyInvitationCodeApi,
    {
      manual: true,
      onSuccess() {
        toast.success(t('修改成功'));
        reload();
      },
    },
  );
  return {
    rebateRules,
    rebateTotalData,
    currentInvitationCodeData,
    currentInvitationCodeLoading,
    invitationCodeQR,
    list,
    loading,
    loadMore,
    error,
    total,
    params,
    recommendForm,
    setRecommendForm,
    reload,
    createInvitationCodeReq,
    memberInvitationInformationData,
    modifyInvitationCodeReq,
    resetPageProps,
  };
};
